//sidebar
// $(document).ready(function () {
//   $("#sidemenu li a").click(function () {
//     $(".sidebar-nav.navbar-collapse.collapse.in").removeClass("in");
//     $("body").css("overflowY", "auto");
//   });
// });

$(document).ready(function () {
  $("#addVar").on("change", function () {
    console.log(this.value);
    if (this.value != "") {
      var cursorPos = $("#tempText").prop("selectionStart");
      var v = $("#tempText").val();
      var textBefore = v.substring(0, cursorPos);
      var textAfter = v.substring(cursorPos, v.length);

      $("#tempText").val(textBefore + "{#" + this.value + "#}" + textAfter);
      //alert('hello');
    }
  });
  /*$('#tempText').on('change', function() {
  $("#original").val(this.value)
  
});*/
});

// lead-list-action-btn
function actionPopup() {
  var actioBtn = document.querySelector(".action-btn");
  var menuPopup = document.querySelector(".follow-up-item");
  menuPopup.classList.add("open");
}
function hidePopup() {
  var menuPopup = document.querySelector(".follow-up-item");
  var hidePopup = document.querySelector(".hide-popup");
  menuPopup.classList.remove("open");
}

// popover
// $.noConflict();
// jQuery(document).ready(function ($) {
//   $('[data-toggle="popover"]').popover();
// });
$(document).ready(function ($) {
  $(function () {
    $('[data-toggle="popover"]').popover();
  });
});


/**
 * this workaround makes magic happen
 * Prevent TinyMCE dialog from blocking focusin jquery
 */
$(document).on("focusin", function (e) {
  if ($(e.target).closest(".tox-dialog").length) {
    e.stopImmediatePropagation();
  }
});